.container {
    max-width: calc(25 * var(--space));
    /*    TODO: remove when normalize css*/
    text-decoration: none;
}

.codeContainer {
    background: white; /* https://qrworld.wordpress.com/2011/08/09/the-quiet-zone/ */
    padding: calc(2 * var(--space));
}
.code {
    height: auto;
    max-width: 100%;
}

.text {
    margin: var(--space) auto 0;
    text-align: center;
    color: var(--white);
    text-transform: uppercase;
}