.avatarImage {
    object-fit: cover;
}
.coverOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: red;
}