.container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.6);
    font-size: var(--font-size--xxl);
    border: solid 1px var(--primary);
    border-radius: 50%;
    color: var(--primary);
}
