:root {
  --base-background-color: #f2eeed;
  --base-black: #1C1C1C;
  --popup-black: #242743;
  --dark-black: #000;
  --dark-blue: #33375A;
  --violet: #6C74B4;

  --white: #FFFFFF;
  --chipsBgColor: #383838;
  --primary: #F7A397;
  --primary-rgb: 247, 164, 151;
  --color-gray-light: #AAAAAA;

  --chipsBorderRadius: 10px;
  --modalBorderRadius: 20px;
  --buttonBorderRadius: 60px;

  --space: 0.5rem; /* 8px on normal screen */

  --font-size-unit: 0.125rem;
  --font-size--xs: calc(5 * var(--font-size-unit)); /* 2.5vw = 10px - xs */
  --font-size--sm: calc(6 * var(--font-size-unit)); /* 3vw = 12px - sm */
  --font-size--md: calc(7 * var(--font-size-unit)); /* 3.5vw = 14px - md */
  --font-size--lg: calc(8 * var(--font-size-unit)); /* 4vw = 16px - lg */
  --font-size--xl: calc(10 * var(--font-size-unit)); /* 5vw = 20px - xl */
  --font-size--xxl: calc(12 * var(--font-size-unit)); /* 6vw = 24px - xxl */

  --font-weight--bold: 700
}
