.preloaderMainWrapper {
    position: absolute !important;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: left;
    z-index: 1;
    transition: opacity 1s;
    pointer-events: none;
}
.wrapper {
    text-align: center;
}
.preloaderHidden {
    opacity: 0;   
}
.preloaderVisible { 
    opacity: 1;
}
.logo {
    max-width: 60px;
    width: 100%;
}
.subtitle {
    font-size: var(--font-size--xxl);
    font-family: Inter, sans-serif;
    color: white;
    margin-top: 10px;
}