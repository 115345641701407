.main {
    padding: calc(3 * var(--space));
}
.status {
    background: linear-gradient(271.2deg, #FFB682 0%, #ED6EA0 100%);
}

.frame {
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: fill;
}

.frameContainer {
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.coverImageContainer {
    display: flex;
    justify-content: center;
    height: 100%;
    overflow: hidden;
    border: 5px solid white;
    box-shadow: 0 0 20px black;
    border-radius: 16px;
    background-color: var(--dark-blue);
}

.coverImageContainerSmall {
    display: flex;
    justify-content: center;
    height: 100%;
    overflow: hidden;
    border: 5px solid white;
    box-shadow: 0 0 8px black;
    border-radius: 16px;
    background-color: var(--dark-blue);
}

.coverImage {
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}

.coverImageFinished {
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    opacity: 0.4;
}
.coverOverlay {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(51, 55, 90, 0.48) 47.4%, rgba(51, 55, 90, 0.72) 62.5%, #33375A 100%);
    padding: calc(2 * var(--space));
    box-sizing: border-box;
}