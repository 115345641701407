.container {
    background-image: url("waitingPanel.png");
    background-size: cover;

    display: flex;
    justify-content: center;
    align-items: center;
}
.spinner {
    position: relative;
    top: 25%;
}