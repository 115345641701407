.StreamInfoSectionV2 {
    display: flex;
    flex-direction: column;
    font-family: Inter, sans-serif;
    max-width: 450px;
    margin-left: 16px;
    margin-right: 16px;
}

.eventTimeTitle {
    font-weight: 700;
    font-size: 30px;
    color: #828BE1;
    margin-bottom: 20px;
}

.eventLive {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.eventLiveTitle {
    font-weight: 700;
    font-size: 30px;
    color: #F56969;
}

.liveIndicator {
    width: 44px;
    height: 44px;
    margin-right: 11px;
}

.eventTitle {
    font-weight: 700;
    font-size: 26px;
    color: #0F111C;
    margin-bottom: 20px;
}

.hostLeft {
    display: flex;
    align-items: center;
}

.hostButton {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text {
    font-weight: 500;
    font-size: 20px;
    color: #494E54;
}

.avatarImage {
    object-fit: cover;
    margin: 8px;
}

.hostName {
    font-weight: 600;
    font-size: 20px;
    color: #0F111C;
    max-width: 270px;
}

.viewsCount {
    font-weight: 500;
    font-size: 16px;
    color: #747980;
    max-width: 270px;
}

.seeMoreStreams {
    margin-top: calc(4 * var(--space));
    color: #F56969;
}