.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.9);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    /* padding: calc(1.5 * var(--space)) var(--space); */

    --header-top-height: calc(0.06 * var(--gameplay-height));
    --header-font-size-for-svg: calc(0.7 * var(--header-top-height));
    font-size: var(--header-font-size-for-svg);
    height: var(--header-top-height);
}
.iconsWrapper {
    display: flex;
    gap: var(--space);
    height: 100%;
    align-items: center;
    margin-right: calc(2 * var(--space));
}

.chatIcon {
    position: relative;
    cursor: pointer;
}
/* .chatIcon svg {
     height: 100%; 
}*/
.chatIconRedDot {
--dot-size: calc(1.05 * var(--space));
 background-color: #F56969;
 width: var(--dot-size);
 height: var(--dot-size);
 position: absolute;
 /* top: 0; */
 top: calc(0.5 * var(--header-top-height) - var(--header-font-size-for-svg)/2 + var(--dot-size)/2);
 right: calc(-1 * var(--space)/4);
 border-radius: var(--space);
}
