.button {
    padding: 0;
    margin: 0;
    /* height: calc(var(--space) * 3); */
    width: auto;
    min-width: auto;

    font-size: var(--header-font-size-for-svg);
}
/* .button svg {
    height: 100%;
    font-size: 3.5vh;
} */