.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.64);

    display: flex;
    justify-content: center;
    align-items: center;
}
.popup {
    background: var(--base-black);
    border-radius: var(--modalBorderRadius);
    padding: calc(3 * var(--space));
    margin: 0 calc(8 * var(--space));
}

.title {
    font-size: var(--font-size--xl);
    font-weight: var(--font-weight--bold);
    color: var(--white);
    text-align: center;
}

.buttonBar {
    margin-top: calc(3 * var(--space));
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: center;
}