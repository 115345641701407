.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--space);
    color: var(--white);
    gap: var(--space);
    font-size: var(--font-size--md);
    text-align: center;
}
.participantsCountContainer {
    display: flex;
    align-items: center;
    gap: 5px;
}
.mobile {
    font-size: var(--font-size--sm);
}
.icon {
    width: calc(3 * var(--space));
    height: calc(3 * var(--space));
}

.hostPictureWrapper {
    width: calc(6.5 * var(--space));
    height: calc(6.5 * var(--space));
    display: flex;
    justify-content: center;
    align-items: center;
}
.hostPictureWrapper img {
    width: calc(6.5 * var(--space));
    height: calc(6.5 * var(--space));
    object-fit: cover;
    border-radius: 50%;
}
.titleWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    text-align: left;
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: var(--font-size--lg);
    color: var(--white);
}
.titleWrapperReversed {
    flex-direction: column-reverse;
}
.mainTitle {
    font-weight: 700;
}
.subTitle {}

@media (max-width: 380px) {
    .container {
        font-size: var(--font-size--xs);
        border-radius: 5px;
    }
}