.getTheAppSection {
    display: flex;
    flex-direction: column;
    border-radius: 18px;
    align-self: center;
    justify-content: center;
    margin-left: calc(5 * var(--space));
}

.container {
    display: flex;
    flex-direction: column;
    font-family: Inter, sans-serif;
    align-items: center;
    background-color: white;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
}

.getTheAppSectionHeaderImage {
    width: 100%;
    /*height: 100%;*/
    object-fit: cover;
}

.getTheAppSectionHeader {
    font-size: 32px;
    font-weight: 800;
    margin-bottom: calc(3 * var(--space));
    color: #F56969;
    font-family: Inter, sans-serif;
}

.getTheAppProList {
    list-style: none;
    margin: 0 0 4rem 0;
    padding: 0;
}

.getTheAppProListItem {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
}

.getTheAppProListItem:last-child {
    margin-bottom: 0;
}

.getTheAppProListItemIcon {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1.5rem;
}

.getTheAppProListItemText {
    font-family: Inter, sans-serif;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.5;
    color: #F56969;
}

.getTheAppProListItemIconEmpty {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1.5rem;
    /*noinspection CssInvalidPropertyValue*/
    display: hidden;
}

.getTheAppQrSection {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
}

.getTheAppQrSectionText {
    font-family: Inter, sans-serif;
    font-size: 22px;
    font-weight: 700;
    line-height: 26px;
    max-width: 120px;
}

.charactersContainer {
    width: 100%;
}