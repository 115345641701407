.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.64);

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 102;
}
.popup {
    width: 100%;
    min-height: 80%;
    margin-top: auto;
    position: relative;
    background-color: var(--white);
    /* background-image: url(../../../../images/characters1.png); */
    background-image: url(../../../../images/download_characters.jpg);
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: contain;
    border-top-left-radius: var(--modalBorderRadius);
    border-top-right-radius: var(--modalBorderRadius);
    padding: calc(2 * var(--space));
    padding-bottom: 50%;
    text-align: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.title {
    font-family: 'Poppins', sans-serif;
    font-size: var(--font-size--xxl);
    font-weight: 500;
    line-height: 132%;
    color: var(--popup-black);
    text-align: center;
}

.downloadButton {
    position: relative;
    background: #6C74B4;
    box-shadow: 0px 0px 8px rgba(66, 70, 106, 0.32);
    border-radius: 1rem;
    color: var(--white);
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: var(--font-size--xxl);
    padding: calc(3.4 * var(--space)) calc(2 * var(--space));
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 40%;
    gap: 1rem;
    text-decoration: none;
}
.closeButton {
    text-align: left;
    left: 0;
    position: relative;
    background: transparent;
    width: min-content;
}