.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 5vw;
  padding: calc(2 * var(--space));
  background-color: var(--violet);
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: all 0.3s;
  z-index: 10;
}
.wrapperHide {
  top: -20vw;
}
.wrapperShow {
  top: 0;
}
.top {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  width: 100%;
}
.title, .counter {
  color: var(--white);
  font-size: var(--font-size--lg);
}
.subtitle {
  color: #FFD78A;
  font-size: var(--font-size--sm);
}