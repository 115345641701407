.container {
    display: flex;
    position: absolute;
    z-index: 101;
    box-sizing: border-box;

    animation: up ease 0.5s;
    animation-delay: 0s;
    animation-fill-mode: forwards;

    border-radius: 12px;
    border: 3px solid rgba(255, 255, 255, 0.70);
    background: linear-gradient(180deg, #FF4F4F 0%, #FF7F63 100%);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.50);

    --banner-height: calc(11 * var(--space));
    --banner-bottom: calc(1 * var(--space));
    left: var(--space);
    right: var(--space);
    bottom: -100%;
    height: var(--banner-height);
}

@keyframes up {
    to {
        bottom: var(--banner-bottom);
    }
}

.content {
    padding: 0 calc(1.5 * var(--space));
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: calc(1.5 * var(--space));
    font-family: 'Poppins', sans-serif;
    color: var(--white);
}

.rightContainer {
    width: calc(2 * var(--banner-height));
    height: var(--banner-height);
}
.rightContainer img {
    height: 100%;
    position: absolute;
    right: 0;
}
.rightContainer img:nth-child(2) {
    right: calc(2.8 * var(--space));
}
.header {
    display: flex;
    align-items: center;

    font-weight: 600;
    font-size: calc(17 / 7 * var(--space));
    line-height: calc(21 / 7 * var(--space));

    color: var(--white);
}

.link {
    text-decoration: none;
    width: 100%;
}