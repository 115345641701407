.wrapper {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    margin-bottom: calc(10 * var(--space));
    font-family: Inter, sans-serif;
    text-align: center;
    gap: 10px;
}
.title {
    text-align: center;
    font-size: var(--font-size--xxl);
    font-weight: 500;
}