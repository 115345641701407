.container {
    display: flex;
    flex-direction: column;
    position: relative;
}
.aboveBackgroundWrapper {
    position: relative;
}
.logoContainer {
    padding-right: calc(2 * var(--space));
    text-align: right;
}

.streamMenuButton {
    display: flex;
    justify-content: center;
    align-items: center;

    height: calc(4 * var(--space));
    margin: 0 0 0 auto;

    background: rgba(var(--primary-rgb), 0.6);
    border-radius: 50%;
    border: none;
}

.leftColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
}
.background {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, #141319 0%, rgba(20, 19, 25, 0.546875) 55.73%, rgba(20, 19, 25, 0) 100%);
    width: 100%;
    height: calc(26 * var(--space));
}