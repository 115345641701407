.iconsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--space);
}

.iconsWrapper button {
    background: transparent;
    border: none;
}
.audienceButtonWrapper {
    background: rgba(128, 130, 156, 0.4);
    border-radius: 5.333vw;
}
.audienceButtonWrapper img {
    width: calc(1.4 * var(--space));
}
.audienceButtonWrapper button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: calc(.3 * var(--space)) var(--space);
    height: calc(2.6 * var(--space));
}
.audienceCount {
    color: var(--white);
    padding: 0 .2rem;
    text-align: center;
    font-size: var(--font-size--lg);
    font-weight: 600;
}