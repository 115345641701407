.container {
    position: relative;
    display: flex;
    margin-bottom: var(--space);
    align-items: center;
    justify-content: center;
    margin-top: auto;
    width: 100%;
}
.filledButton {
    min-width: auto;
    background-color: var(--primary);
    border-radius: 100%;
    width: calc(6 * var(--space));
    height: calc(6 * var(--space));
}

.heartIcon {
    min-width: auto;
    width: calc(6 * var(--space));
    height: calc(6 * var(--space));
    margin-right: 1rem;
}

.infoContainer {
    cursor: pointer;
    position: absolute;
    right: 0;
}

.infoIcon {
    width: calc(4 * var(--space));
    height: calc(4 * var(--space));
}