.container {
    position: relative;
    display: flex;
    justify-content: center;
}

.streamContainer {
    position: relative;
    display: flex;
    justify-content: center;
}