.wrapper {
  --tooltip-width: calc(20 * var(--space));
  --tooltip-height: calc(15 * var(--space));
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  width: var(--tooltip-width);
  height: var(--tooltip-height);
  cursor: pointer;
  border-radius: calc(2 * var(--space));
  font-size: calc(18 * var(--font-size-unit));
  line-height: calc(18 * var(--font-size-unit));
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Eponymous";
  animation: upAndDown 1s ease infinite;
}

.pointer {
  --pointer-size: calc(0.1 * var(--tooltip-width));
  position: absolute;
  top: calc(-1 * var(--pointer-size)/2);
  right: calc(var(--pointer-size));
  transform: rotate(45deg);
  width: var(--pointer-size);
  height: var(--pointer-size);
  background-color: white;
}

@keyframes upAndDown {
  0% {
    margin-top: 0;
  }
  50% {
    margin-top: calc(1 * var(--space));
  }
  100% {
    margin-top: 0;
  }
}
