.wrapper {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    margin-bottom: calc(10 * var(--space));
    font-family: Inter, sans-serif;
}
.title {
    font-size: var(--font-size--xxl);
    font-weight: 500;
}
.numbersWrapper {
    display: flex;
    gap: 12px;
}
.numbers {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.time {
    font-size: calc(2 * var(--font-size--xxl));
    font-weight: 400;
    text-align: center;
    color: white;
}
.timeName {
    color: #99A4FF;
    font-size: var(--font-size--lg);
    text-transform: capitalize;
}