.wrapper {
  display: flex;
  margin-left: calc(0.5 * var(--space));
}
.title {
  /* font-size: var(--font-size--lg); */
  font-size: 0.5em;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--white);
  margin-left: calc(0.5 * var(--space));
}